import React, { FC, memo } from 'react';

import { Container } from './styles';

export const Button: FC<any> = memo(props => {
  const { label, className, onClick, style, type } = props;

  return (
    <Container type={type} className={className} style={style} onClick={e => onClick(e)}>
      {label}
    </Container>
  );
});
