import styled from 'styled-components';

import { rem } from 'styles/utils';
import { BREAKPOINT } from 'styles/variables';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
`;

export const TopBelt = styled.div`
  width: 100%;
  height: 50px;
  background-color: #fec768;
  @media (max-width: ${BREAKPOINT.XS}px) {
    height: 17px;
  }
`;

export const BoxContainer = styled.div`
  display: block;
  padding: ${rem(40)};
  @media (max-width: ${BREAKPOINT.XS}px) {
    padding: ${rem(20)};
  }
`;
