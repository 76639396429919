import styled from 'styled-components';

import { BREAKPOINT } from 'styles/variables';
import { rem } from 'styles/utils';

export const Container = styled.div`
  display: block;
`;

export const Header = styled.header`
  max-width: 75%;
  h4 {
    margin-bottom: ${rem(35)};
  }
  @media (max-width: ${BREAKPOINT.MD}px) {
    max-width: 100%;
  }
`;

export const LinkPage = styled.a`
  color: #000000;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: ${rem(16)};
`;

export const PhoneInfo = styled.div`
  display: block;
  font-size: ${rem(16)};
  line-height: ${rem(22)};
  margin: ${rem(35)} 0;
`;

export const PhoneNumber = styled.a`
  display: block;
  margin-bottom: ${rem(40)};
  img {
    max-width: ${rem(250)};
  }
`;

export const ActionsContainer = styled.div`
  button {
    display: inline-block;
  }
`;
