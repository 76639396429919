import styled from 'styled-components';

import { rem } from 'styles/utils';
import { BREAKPOINT } from 'styles/variables';

export const Container = styled.form`
  display: block;
  input {
    width: 337px;
  }
  @media (max-width: ${BREAKPOINT.XS}px) {
    input {
      width: 100%;
    }
  }
`;

export const QuestionTitle = styled.div`
  font-size: ${rem(16)};
  font-weight: bold;
  display: block;
  margin: ${rem(50)} 0 ${rem(40)};
  @media (max-width: ${BREAKPOINT.XS}px) {
    margin: ${rem(20)} 0;
  }
`;

export const QuestionRules = styled.div`
  display: flex;
  width: 337px;
  margin: ${rem(40)} 0;
  @media (max-width: ${BREAKPOINT.XS}px) {
    width: 100%;
  }
`;

export const QuestionCheckbox = styled.div`
  display: block;
  flex: 0 0 30px;
`;

export const QuestionRuleInfo = styled.div`
  color: #656565;
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  margin-left: ${rem(10)};
`;

export const ActionsContainer = styled.div`
  button {
    display: inline-block;
  }
`;

export const InputGroup = styled.div`
  display: block;
  margin: ${rem(12)} 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;