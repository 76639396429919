import React, { FC, useState } from 'react';
import withSizes from 'react-sizes';

import { SidebarLeft, SidebarRight } from 'components';
import { useEffectAsync, request } from 'shared';

const App: FC<any> = props => {
  const { isMobile } = props;
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);

  useEffectAsync(async () => {
    const response = await request({ method: 'get', url: '/data/data.json' });

    setQuestions(response.questions);
  });

  return (
    <div className="ns-wrapper">
      {!isMobile ? <SidebarLeft /> : null}
      {questions && questions.length ? (
        <SidebarRight questions={questions} answers={answers} setAnswers={setAnswers} setQuestions={setQuestions} />
      ) : null}
    </div>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 996,
});

export default withSizes(mapSizesToProps)(App);
