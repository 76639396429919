import React, { FC, memo } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Container, Title, Description } from './styles';

export const InfoBox: FC<any> = memo(props => {
  const {
    question: { infobox },
  } = props;
  return infobox ? (
    <Container bgColor={infobox.styles.bgColor}>
      <Title>{infobox.title}</Title>
      <Description>{ReactHtmlParser(infobox.description)}</Description>
    </Container>
  ) : null;
});
