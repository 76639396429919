import React, { FC, memo, Fragment, useState } from 'react';

import { EFieldType, request } from 'shared';

import PhoneOneImg from 'assets/images/phone-1.png';

import {
  Container,
  SummaryAnswersContainer,
  AnswersContainer,
  AnswerContainer,
  AnswerTitle,
  AnswerValue,
  SummaryFooterContainer,
  SummaryResultContainer,
  ResultTitle,
  ResultPhoneRow,
  ContactForm,
  ContactFields,
  ContactInput,
  ResultPhoneInfo,
  ResultPhoneNumber,
  ContactButton,
  SummaryWhiteContainer,
  CallToUsRow,
  PhoneContactRow,
  ContactFormButton,
  SuccessMessage,
  HomePageLink,
  SummaryTextFooter,
} from './styles';

export const SummaryForm: FC<any> = memo(props => {
  const { answers, email } = props;

  const [isSuccess, setSuccess] = useState(false);
  const [isSending, setSending] = useState(false);

  const selectedAnswers = answers.filter(a => a.show);

  const renderAnswerValue = question => {
    if ([EFieldType.CHECKBOX, EFieldType.RADIO].includes(question.type)) {
      return question.options.map((option, i) => (
        <Fragment key={`AnswerValue${option.id}${i}`}>
          <AnswerValue>
            {option.label} {option.inputValue || ''}
          </AnswerValue>
        </Fragment>
      ));
    }

    if ([EFieldType.INPUT].includes(question.type)) {
      return <AnswerValue>{question.value}</AnswerValue>;
    }
  };

  const renderAnswers = () => {
    return (
      <AnswersContainer>
        {selectedAnswers.map((answer, i) => (
          <AnswerContainer key={`Answer${i}`}>
            <AnswerTitle>
              {i + 1}. {answer.question}
            </AnswerTitle>
            {renderAnswerValue(answer)}
          </AnswerContainer>
        ))}
      </AnswersContainer>
    );
  };

  // const handleSubmit = e => {
  //   const { phone, name } = e.target;
  //   e.preventDefault();

  //   if (!phone || !phone.value || !name || !name.value) {
  //     return;
  //   }
  //   const data = {
  //     phone: e.target.phone.value,
  //     name: e.target.name.value,
  //     answers: selectedAnswers,
  //     email,
  //   };

  //   console.error(data);

  //   setSending(true);
  //   request({ method: 'post', url: '/form', data })
  //     .then(() => {
  //       setSuccess(true);
  //       setSending(false);
  //     })
  //     .catch(() => {
  //       setSending(false);
  //     });
  // };

  return (
    <Container>
      <SummaryAnswersContainer>
        <h4 className="ns-question-title font-playfair">Podsumowanie wykonanego testu:</h4>
        {renderAnswers()}
      </SummaryAnswersContainer>
      <SummaryResultContainer>
        <ResultTitle>
          Wynik testu daje szansę abyś <span className="ns-orange-400">wreszcie pozbył/ła się długów</span>
        </ResultTitle>
        <ResultPhoneRow>
          <ResultPhoneInfo>Zapisz nasz numer jeśli nie odbierasz wszystkich połączeń.:</ResultPhoneInfo>
          <ResultPhoneNumber href="tel:660 554 544">
            <img src={PhoneOneImg} alt="660 554 544" />
          </ResultPhoneNumber>
        </ResultPhoneRow>
        {/* {!isSuccess && !isSending ? (
          <ContactForm autoComplete="off" onSubmit={handleSubmit}>
            <ContactFields>
              <ContactInput type="text" name="phone" placeholder="+48 654 123 789" />
              <ContactInput type="text" name="name" placeholder="Imię" />
            </ContactFields>
            <ContactButton type="submit" name="send">
              Tak, proszę zadzwonić
            </ContactButton>
          </ContactForm>
        ) : null} */}
        {/* {isSuccess ? <SuccessMessage>Zapytanie zostało wysłane</SuccessMessage> : null} */}
      </SummaryResultContainer>
      {/* <SummaryWhiteContainer>
        <CallToUsRow>lub zadzwoń do nas</CallToUsRow>
        <PhoneContactRow>
          <a href="tel:660 554 544">
            <img src={PhoneOneImg} alt="660 554 544" />
          </a>
        </PhoneContactRow>
        <ResultPhoneRow>
          <ResultPhoneInfo>a jeżeli masz jakieś pytanie i wolisz napisać to wykorzystaj:</ResultPhoneInfo>
          <ResultPhoneNumber>
            <ContactFormButton href="https://nowystart.pl/#formularz">Formularz kontaktowy</ContactFormButton>
          </ResultPhoneNumber>
        </ResultPhoneRow>
      </SummaryWhiteContainer> */}
      <SummaryFooterContainer>
        <HomePageLink href="https://nowystart.pl/">Powrót do Strony głównej</HomePageLink>
        <SummaryTextFooter>
          Poświęcony dziś czas to{' '}
          <strong>
            <span className="ns-purple-400">ogromną inwestycja w Twoją Nową Przyszłość</span>
          </strong>
          .<br /> Jesteśmy pewni, że{' '}
          <strong>
            <span className="ns-orange-400">wspólnie osiągniemy sukces.</span>
          </strong>
        </SummaryTextFooter>
      </SummaryFooterContainer>
    </Container>
  );
});
