import React, { FC, useState, useEffect, Fragment } from 'react';

import { Button, Checkbox, Radio, InfoBox } from 'components';
import { EFieldType } from 'shared';

import {
  Container,
  QuestionContainer,
  QuestionDescription,
  FieldInputContainer,
  FieldContainer,
  FieldsContainer,
  ActionsContainer,
} from './styles';

export const Question: FC<any> = props => {
  const { question, questionIndex, updateQuestions, nextStep, prevStep } = props;
  const [radioValue, setRadioValue] = useState(null);
  const [inputValue, setInputValue] = useState(question.value || '');

  const isValid = () => {
    if (question.type === EFieldType.RADIO) {
      return !!question.options.filter(o => o.checked).length;
    }

    if (question.type === EFieldType.INPUT) {
      return question.value && question.value.length;
    }

    return true;
  };

  const [valid, setValid] = useState(isValid());

  useEffect(() => {
    setValid(isValid());
  }, [question]);

  const onCheckboxChange = (e, option) => {
    option.checked = e.target.checked;
  };

  const onRadioChange = value => {
    setRadioValue(value);
    question.options.forEach((o, i) => {
      question.options[i].checked = o.id === value;
    });

    updateQuestions(question);
    setValid(isValid());
  };

  const onInputChange = e => {
    question.value = e.target.value;

    setInputValue(question.value);
    updateQuestions(question);
    setValid(isValid());
  };

  const renderCheckbox = () => {
    return (
      <Fragment>
        {question.options.map((option, i) => (
          <FieldContainer key={`FieldCheckbox${i}`}>
            <Checkbox label={option.label} option={option} onChange={onCheckboxChange} />
          </FieldContainer>
        ))}
      </Fragment>
    );
  };

  const renderRadio = () => {
    return question.options.length ? (
      <form>
        {question.options.map((option, i) => (
          <FieldContainer key={`FieldCheckbox${i}`}>
            {/* {option.checked ? 'checked' : 'not checked'} */}
            <Radio
              name={`Question${question.id}`}
              value={option.id}
              checked={option.checked}
              label={option.label}
              onRadioChange={e => onRadioChange(e)}
            />
          </FieldContainer>
        ))}
      </form>
    ) : null;
  };

  const renderInput = () => {
    return (
      <FieldInputContainer>
        <input
          type="text"
          name="city"
          value={inputValue}
          placeholder={question.placeholder}
          autoComplete="off"
          onChange={onInputChange}
        />
      </FieldInputContainer>
    );
  };

  const renderFields = () => {
    switch (question.type) {
      case EFieldType.RADIO:
        return renderRadio();
      case EFieldType.CHECKBOX:
        return renderCheckbox();
      case EFieldType.INPUT:
        return renderInput();
      default:
        return null;
    }
  };

  const getPureQuestion = () => {
    return {
      id: question.id,
      title: question.title,
      question: question.question,
      type: question.type,
      show: question.show,
      value: question.value,
      options: question.options ? question.options.filter(o => o.checked) : [],
    };
  };

  return (
    <Container>
      <InfoBox question={question} />
      <QuestionContainer>
        {question.title ? <h4 className="ns-question-title font-playfair">{question.title}</h4> : null}
        {question.question ? <QuestionDescription>{question.question}</QuestionDescription> : null}
        <FieldsContainer>{renderFields()}</FieldsContainer>
        {!question.isSummary ? (
          <ActionsContainer>
            {questionIndex ? (
              <Button
                type="button"
                label="Wróć"
                style={{ marginRight: '2rem', backgroundColor: '#E1E0DE' }}
                onClick={e => prevStep(e)}
              />
            ) : null}
            <Button
              type="button"
              label="Dalej"
              className={!valid ? `ns-opacity-50` : null}
              onClick={e => (valid ? nextStep(e, getPureQuestion()) : null)}
            />
          </ActionsContainer>
        ) : null}
      </QuestionContainer>
    </Container>
  );
};
