import axios from 'axios';

const client = axios.create({
  baseURL: 'https://test.nowystart.pl/api/', // window.location.origin,
  // baseURL: 'http://nowy-start-be.local/', // window.location.origin,
});

export const request = (options?: any) => {
  const onSuccess = (response: any) => response.data;
  const onError = (error: any) => {
    console.error('Request Failed:', error.config);

    if (error.response) {
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
    } else {
      console.error('Error Message:', error.message);
    }

    return Promise.reject(error.response || error.message);
  };
  return client(options)
    .then(onSuccess)
    .catch(onError);
};
