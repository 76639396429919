export const rem = (value: number | number[], baseValue = 16) => {
  if (Array.isArray(value)) {
    let result = '';
    for (const elem of value) {
      result += elem / baseValue + 'rem ';
    }
    return result;
  }
  return value / baseValue + 'rem';
};
