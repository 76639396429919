import React, { FC, memo } from 'react';

import { Wrapper, Container, Top, Title, Description, Footer } from './styles';

import Logo from 'assets/images/logo.png';

export const SidebarLeft: FC = memo(props => {
  return (
    <Wrapper>
      <Container>
        <Top>
          <Title>Oceń swoje szanse</Title>
          <Description>sprawdź swoje szanse na ogłoszenie Upadłości Konsumenckiej i życie bez długów</Description>
        </Top>
        <Footer>
          <a href="https://nowystart.pl">
            <img src={Logo} alt="nowystart.pl" />
          </a>
        </Footer>
      </Container>
    </Wrapper>
  );
});
