import React, { FC, memo } from 'react';

import { Button } from 'components';

import PhoneImg from 'assets/images/phone-1.png';

import { Container, Header, LinkPage, PhoneInfo, PhoneNumber } from './styles';
import { ActionsContainer } from 'components/question/styles';

export const SummaryWithoutHelp: FC<any> = memo(props => {
  const { prevStep } = props;

  const redirect = () => {
    window.location.href = 'https://www.nowystart.pl';
    return;
  };

  return (
    <Container>
      <Header>
        <h4 className="ns-question-title font-playfair">
          Bardzo dziękujemy za poświęcony czas i wypełnienie testu,
          <br />
          <strong>
            <span className="ns-orange-400"> jeśli chcesz uzyskać więcej informacji zapraszamy do lektury strony</span>
          </strong>
        </h4>
      </Header>
      <LinkPage href="https://www.nowystart.pl">www.nowystart.pl</LinkPage>
      <PhoneInfo>lub telefon do naszej Kancelarii:</PhoneInfo>
      <PhoneNumber href="tel:660 554 544">
        <img src={PhoneImg} alt="+48 660 554 544" />
      </PhoneNumber>
      <ActionsContainer>
        <Button
          type="button"
          label="Wróć"
          style={{ marginRight: '2rem', backgroundColor: '#E1E0DE' }}
          onClick={e => prevStep(e)}
        />
        <Button type="button" label="Dalej" onClick={e => redirect()} />
      </ActionsContainer>
    </Container>
  );
});
