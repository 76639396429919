import styled from 'styled-components';

import { rem } from 'styles/utils';

export const Container = styled.div`
  width: 100%;
  color: #000000;
  padding: ${rem(20)} ${rem(30)};
  background-color: ${props => props.bgColor || '#fff4e0'};
  margin-bottom: ${rem(40)};
`;

export const Title = styled.h2`
  font-size: ${rem(32)};
  font-family: Playfair Display;
  margin: ${rem(10)} 0 ${rem(30)};
`;

export const Description = styled.div`
  display: block;
  font-size: ${rem(14)};
  font-weight: bold;
  line-height: ${rem(19)};
  margin: ${rem(10)} 0 ${rem(20)};
  padding-right: ${rem(50)};
`;
