import styled from 'styled-components';

import { rem } from 'styles/utils';

import SummaryPoint from 'assets/images/summary-point.png';
import { BREAKPOINT } from 'styles';

export const Container = styled.div``;

export const AnswersContainer = styled.div`
  width: 100%;
  display: block;
`;

export const AnswerContainer = styled.div`
  width: 100%;
  display: block;
  position: relative;
  padding: ${rem(16)} 0;
  padding-left: ${rem(40)};
  padding-right: ${rem(6)};
  &:before {
    width: ${rem(24)};
    height: ${rem(24)};
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: url(${SummaryPoint}) no-repeat left center;
  }
`;

export const AnswerTitle = styled.h5`
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-size: ${rem(18)};
  font-weight: bold;
  margin: 0;
`;

export const AnswerValue = styled.div`
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-size: ${rem(16)};
  margin-top: ${rem(8)};
  margin: 0;
`;

export const SummaryAnswersContainer = styled.div`
  padding: 0 ${rem(60)} ${rem(20)};
  @media (max-width: ${BREAKPOINT.SM}px) {
    padding: 0 ${rem(20)} ${rem(20)};
  }
`;

export const SummaryFooterContainer = styled.div`
  width: 100%;
  padding: ${rem(50)} ${rem(60)};
  font-size: 24px;
  font-family: Playfair Display;
  line-height: 32px;
  background-color: #ffffff; // #fbefd6;
  @media (max-width: ${BREAKPOINT.SM}px) {
    font-size: 18px;
    line-height: 26px;
    padding: ${rem(30)} ${rem(20)};
  }
`;

export const SummaryResultContainer = styled.div`
  width: 100%;
  padding: ${rem(50)} ${rem(60)};
  background-color: #fbefd6;
  // margin-bottom: ${rem(40)};
  @media (max-width: ${BREAKPOINT.SM}px) {
    padding: ${rem(30)} ${rem(20)};
  }
`;

export const ResultTitle = styled.h3`
  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin: ${rem(0)} 0 ${rem(30)};
`;

export const ResultPhoneRow = styled.div`
  display: flex;
  align-items: center;
  max-width: ${rem(1100)};
  @media (max-width: ${BREAKPOINT.SM}px) {
    display: block;
  }
`;

export const ResultPhoneInfo = styled.div`
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  line-height: 22px;
  padding-right: ${rem(38)};
`;

export const ResultPhoneNumber = styled.a`
  display: inline-block;
  img {
    max-width: 250px;
  }
  @media (max-width: ${BREAKPOINT.SM}px) {
    padding-top: ${rem(25)};
  }
`;

export const ContactForm = styled.form`
  display: block;
  margin-top: ${rem(40)};
  padding-bottom: ${rem(20)};
`;

export const ContactInput = styled.input`
  width: 340px;
  display: block;
  color: #c6c6c6;
  background: transparent;
  padding: ${rem(12)} 0;
  margin: ${rem(16)} 0;
  font-size: 14px;
  @media (max-width: ${BREAKPOINT.XS}px) {
    width: 100%;
  }
`;

export const ContactFields = styled.div`
  display: block;
  padding-bottom: ${rem(40)};
`;

export const ContactButton = styled.button`
  width: 280px;
  padding: ${rem(20)} ${rem(10)};
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  border-radius: ${rem(50)};
  background-color: #f5862c;
  text-align: center;
  text-transform: uppercase;
  border: none;
`;

export const HomePageLink = styled.a`
  padding: ${rem(20)} ${rem(50)};
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  border-radius: ${rem(50)};
  background-color: #f5862c;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  border: none;
`;

export const SummaryTextFooter = styled.div`
  width: 100%;
  display: block;
  margin-top: ${rem(40)};
`;

export const SummaryWhiteContainer = styled.div`
  width: 100%;
  padding: ${rem(6)} ${rem(60)} ${rem(40)};
  margin-bottom: ${rem(40)};
  @media (max-width: ${BREAKPOINT.SM}px) {
    padding: ${rem(6)} ${rem(30)};
  }
`;

export const CallToUsRow = styled.div`
  display: block;
  padding-left: ${rem(60)};
  margin: ${rem(6)} 0;
  font-size: 14px;
`;

export const PhoneContactRow = styled.div`
  display: block;
  padding: ${rem(40)} 0 ${rem(50)};
  img {
    max-width: 250px;
  }
`;

export const ContactFormButton = styled.a`
  padding: ${rem(20)} ${rem(40)};
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  border-radius: ${rem(50)};
  background-color: #e0e0e0;
  text-align: center;
  text-transform: uppercase;
  border: none;
  text-decoration: none;
  color: #000000;
  display: block;
  // margin-top: ${rem(20)};
`;

export const SuccessMessage = styled.div`
  width: 100%;
  display: block;
  margin: ${rem(35)} 0 ${rem(10)};
  font-size: ${rem(19)};
  font-weight: bold;
  color: #f5862c;
`;
