import styled from 'styled-components';

import { rem } from 'styles/utils';
import { BREAKPOINT } from 'styles/variables';

export const Container = styled.div`
  width: 100%;
`;

export const InfoContainer = styled.div`
  display: block;
  font-size: ${rem(16)};
  font-weight: bold;
  line-height: ${rem(22)};
  padding: ${rem(40)} ${rem(30)};
  color: #ffffff;
  background-color: #f5862c;
`;

export const ContactContainer = styled.div`
  display: block;
  margin: ${rem(20)} 0;
  padding: ${rem(20)} ${rem(30)};
  @media (max-width: ${BREAKPOINT.XS}px) {
    padding: ${rem(20)} 0;
  }
`;

export const EmailAddress = styled.div`
  display: inline-block;
  font-family: Proxima Nova;
  font-size: ${rem(18)};
  line-height: ${rem(22)};
  text-decoration: underline;
  padding: ${rem(12)} ${rem(30)};
  background-color: #fff4e0;
  color: #653159;
  margin-top: ${rem(20)};
  a {
    font-family: 'Open Sans', sans-serif;
    color: #653159;
  }
  @media (max-width: ${BREAKPOINT.XS}px) {
    width: 200px;
    display: flex;
  }
`;

export const PhoneNumber = styled.a`
  display: inline-block;
  vertical-align: middle;
  padding-right: ${rem(60)};
  // margin-bottom: ${rem(20)};
  img {
    max-width: ${rem(250)};
  }
`;

export const ActionsContainer = styled.div`
  button {
    display: inline-block;
  }
`;
