import styled from 'styled-components';

import { rem } from 'styles/utils';
import { BREAKPOINT } from 'styles/variables';

export const Container = styled.div`
  width: 100%;
  display: block;
`;

export const QuestionContainer = styled.div`
  padding: 0 ${rem(30)};
  @media (max-width: ${BREAKPOINT.XS}px) {
    padding: 0 ${rem(10)};
  }
`;

export const QuestionDescription = styled.div`
  font-size: ${rem(16)};
  font-weight: bold;
  line-height: ${rem(22)};
`;

export const FieldInputContainer = styled.div`
  display: block;
  margin: ${rem(12)} 0;
  input {
    min-width: ${rem(300)};
    font-family: 'Open Sans', sans-serif;
  }
`;

export const FieldContainer = styled.div`
  display: block;
  margin: ${rem(12)} 0;
`;

export const FieldsContainer = styled.div`
  display: block;
  padding: ${rem(15)} 0;
`;

export const ActionsContainer = styled.div`
  button {
    display: inline-block;
  }
`;
