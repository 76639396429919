import styled from 'styled-components';

import { rem } from 'styles/utils';

export const Container = styled.button`
  width: ${rem(170)};
  height: ${rem(50)};
  display: block;
  color: #ffffff;
  font-size: ${rem(14)};
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  background-color: #f5862c;
  text-transform: uppercase;
  border-radius: ${rem(50)};
  border: 0;
  cursor: pointer;
  margin: ${rem(15)} 0;
`;
