import styled from 'styled-components';

import { rem } from 'styles/utils';

import CheckboxActive from 'assets/images/checkbox-active.png';

export const Wrapper = styled.div`
  position: relative;
`;

export const Container = styled.label`
  display: inline-flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  margin-right: ${rem(20)};
  margin-left: 0;
  line-height: ${rem(19)};
  input.ns-checkbox {
    width: ${rem(19)};
    height: ${rem(19)};
    cursor: pointer;
    opacity: 0;
    margin-left: 0;
    margin-right: ${rem(20)};
    border: 1px solid #e0e0e0;
    background: #ffffff;
    border-radius: ${rem(3)};
    + .ns-checkbox-area {
      width: ${rem(19)};
      height: ${rem(19)};
      display: inline-block;
      position: absolute;
      box-sizing: border-box;
      top: ${rem(3)};
      border: 1px solid #e0e0e0;
      background: #ffffff;
      border-radius: ${rem(3)};
    }
    &:checked {
      + .ns-checkbox-area {
        border-color: #ff4949;
        background: transparent url(${CheckboxActive}) center center no-repeat;
      }
    }
  }
`;

export const InputContainer = styled.div`
  display: inline-block;
  margin-left: ${rem(10)};
`;
