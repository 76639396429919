import React, { FC, memo, useState, useEffect } from 'react';

import { Container, Wrapper, InputContainer } from './styles';

export const Checkbox: FC<any> = memo(props => {
  const { label, option, onChange } = props;

  const [checked, setChecked] = useState(false);
  const [inputValue, setInputValue] = useState(option.inputValue || '');

  useEffect(() => {
    setChecked(option.checked);
  }, [option.checked]);

  const handleChange = e => {
    setChecked(!checked);
    if (onChange) {
      onChange(e, option, !checked);
    }
  };

  const handleInputChange = e => {
    e.preventDefault();
    option.inputValue = e.target.value;
    setInputValue(option.inputValue);
  };

  const renderInput = () => {
    return (
      <InputContainer>
        <input type="text" placeholder={option.placeholder} value={inputValue} onChange={handleInputChange} />
      </InputContainer>
    );
  };

  return (
    <Wrapper>
      <Container className={`ns-checkbox-wrapper`}>
        <input type="checkbox" className="ns-checkbox" checked={checked} onChange={handleChange} />
        <span className="ns-checkbox-area"></span>
        <div>
          {label}
          {option.hasInput ? renderInput() : null}
        </div>
      </Container>
    </Wrapper>
  );
});
