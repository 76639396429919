import styled from 'styled-components';

import { rem } from 'styles/utils';

export const Container = styled.div`
  display: flex;
  // &:hover .checkmark:after {
  //   display: block;
  //   background-color: #ffd5d5;
  // }
`;

export const InputContainer = styled.div`
  width: ${rem(19)};
  height: ${rem(19)};
  display: block;
  position: relative;
  cursor: pointer;
  // margin-right: ${rem(20)};
  flex: 0 0 ${rem(39)};
`;

export const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  &:checked ~ .checkmark {
  }
  &:checked ~ .checkmark:after {
    display: block;
  }
`;

export const RadioCheckmark = styled.label`
  width: ${rem(19)};
  height: ${rem(19)};
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  &:after {
    width: ${rem(12)};
    height: ${rem(12)};
    content: '';
    display: none;
    position: absolute;
    top: ${rem(2)};
    left: ${rem(2.5)};
    background-color: #ff4949 !important;
    border-radius: 50%;
  }
`;

export const RadioLabel = styled.label`
  cursor: pointer;
  font-size: ${rem(14)};
`;
