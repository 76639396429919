import styled from 'styled-components';

import { rem } from 'styles/utils';

import SidebarBg from 'assets/images/bg1.jpg';

export const Wrapper = styled.div`
  position: relative;
  width: 460px;
  height: 100%;
  min-height: 100vh;
  flex: 0 0 460px;
`;

export const Container = styled.div`
  width: 460px;
  height: 100%;
  min-height: 100vh;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: ${rem(60)};
  background: url(${SidebarBg}) top no-repeat;
  background-size: cover;
  position: fixed;
`;

export const Top = styled.div`
  display: block;
  margin-top: ${rem(90)};
  padding-right: ${rem(50)};
`;

export const Title = styled.h2`
  font-size: ${rem(32)};
  font-family: Playfair Display;
  font-weight: bold;
  line-height: ${rem(43)};
  margin: ${rem(10)} 0 ${rem(32)};
`;

export const Description = styled.div`
  max-width: ${rem(325)};
  display: block;
  font-size: ${rem(14)};
  line-height: ${rem(19)};
  // padding-right: ${rem(25)};
`;

export const Footer = styled.div`
  width: 100%;
  display: block;
  margin-bottom: ${rem(50)};
`;
