import React, { FC, memo, useEffect, useState } from 'react';

import { Button, Checkbox } from 'components';
import { request } from 'shared';

import {
  Container,
  QuestionTitle,
  QuestionRules,
  QuestionCheckbox,
  QuestionRuleInfo,
  ActionsContainer,
  InputGroup,
} from './styles';

export const SummaryEmail: FC<any> = memo(props => {
  const { answers, question, questionIndex, prevStep, nextStepWithEmail } = props;
  const selectedAnswers = answers.filter(a => a.show);

  const isValid = () => {
    return !!(checked && email && email.length);
  };

  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [valid, setValid] = useState(isValid());
  const [isSending, setSending] = useState(false);

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      setValid(validFields());
    }
    return () => isSubscribed = false;
  }, [email, phone, name, checked]);

  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const onInputChange = e => {
    const { value } = e.target;
    setEmail(value);
  };

  const onPhoneChange = e => {
    const { value } = e.target;
    setPhone(value);
  };

  const onNameChange = e => {
    const { value } = e.target;
    setName(value);
  };

  const onCheckboxChange = (x, y, z) => {
    setChecked(z);
  };

  const validFields = () => {
    const eLength = email && email.length;
    const nLength = name && name.length;
    const pLength = phone && phone.length;

    return checked && eLength && nLength && pLength && validateEmail(email);
  }

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    if (!valid) {
      return;
    }

    const data = {
      answers: selectedAnswers,
      email,
      phone,
      name,
    };

    setSending(true);
    request({ method: 'post', url: '/answers', data })
      .then(() => {
        setSending(false);
        nextStepWithEmail(e, {
          id: question.id,
          nextId: question.nextId,
          email,
          name,
          phone,
        });
      })
      .catch(() => {
        setSending(false);
      });
  };

  return (
    <Container autoComplete="off" onSubmit={handleSubmit}>
      <h4 className="ns-question-title font-playfair">
        bardzo dziękujemy za poświęcony czas,
        <br />
        to{' '}
        <strong>
          <span className="ns-orange-400">pierwszy Twój krok do wyjścia z długów</span>
        </strong>
      </h4>
      <QuestionTitle>wpisz adres e-mail, aby uzyskać wynik testu:</QuestionTitle>
      <InputGroup>
        <input
          type="text"
          name="email"
          placeholder="wpisz tutaj adres e-mail"
          autoComplete="off"
          onChange={onInputChange}
        />
      </InputGroup>
      <InputGroup>
        <input
          type="text"
          name="phone"
          placeholder="numer telefonu"
          autoComplete="off"
          onChange={onPhoneChange}
        />
      </InputGroup>
      <InputGroup>
        <input
          type="text"
          name="name"
          placeholder="imię"
          autoComplete="off"
          onChange={onNameChange}
        />
      </InputGroup>
      <QuestionRules>
        <QuestionCheckbox>
          <Checkbox label="" option={{ checked }} onChange={onCheckboxChange} />
        </QuestionCheckbox>
        <QuestionRuleInfo
          onClick={e => {
            setChecked(!checked);
            setValid(!!(!checked && email && email.length && validateEmail(email)));
          }}
        >
          Zgodnie z zasadami <strong>Regulaminu</strong> i <strong>Polityki Prywatności</strong> zgadzam się na
          przetwarzanie moich danych osobowych przez Administratora – B2B sp z o.o., także w celach marketingowych
          (również analizowanie i profilowanie).
        </QuestionRuleInfo>
      </QuestionRules>

      <ActionsContainer>
        {questionIndex ? (
          <Button
            type="button"
            label="Wróć"
            style={{ marginRight: '2rem', backgroundColor: '#E1E0DE' }}
            onClick={e => prevStep(e)}
          />
        ) : null}
        <Button type="submit" label="Dalej" className={!valid ? `ns-opacity-50` : null} onClick={() => {}} />
      </ActionsContainer>
    </Container>
  );
});
