import React, { FC, useState, memo } from 'react';

import { Question, Summary4B, SummaryForm, SummaryEmail, SummaryWithoutHelp } from 'components';
import { EFieldType } from 'shared';

import { BoxContainer, Container, TopBelt } from './styles';

const SUMMARY_STEPS = ['summary-4B', 'summary-email', 'summary-form', 'summary-without-help'];

const getCurrentQuestion = (questions, id) => {
  const question = questions.find(q => q.id === id);
  return question || null;
};

const getAnswerTotal = (answers, id) => {
  const result = {
    index: -1,
    answer: null,
  };
  answers.forEach((a, i) => {
    if (a.id === id) {
      result.answer = a;
      result.index = i;
    }
  });
  return result;
};

export const SidebarRight: FC<any> = props => {
  const { answers, questions, setAnswers, setQuestions } = props;
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [question, setQuestion] = useState(getCurrentQuestion(questions, questions[0].id));
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');

  const updateQuestions = question => {
    let index = 0;

    questions.forEach((q, i) => {
      if (q.id === question.id) {
        index = i;
        q = question;
      }
    });

    questions[index] = question;

    setQuestions(questions);
  };

  const nextStep = (event, q) => {
    let nextId = question.nextId;

    if (question.type === EFieldType.RADIO) {
      const option = q.options[0];
      if (option.nextId) {
        nextId = option.nextId;
      }
    }

    if (nextId.startsWith('http')) {
      window.location.href = nextId;
      return;
    }

    const { answer, index } = getAnswerTotal(answers, q.id);

    if (answer) {
      answers[index] = q;
      if (answer.type === EFieldType.RADIO) {
        const option = answer.options[0];
        if (option.id !== q.options[0].id && option.nextId) {
          if (option.nextId !== q.options[0].nextId) {
            const nextTotal = getAnswerTotal(answers, option.nextId);
            if (nextTotal.answer) {
              answers.splice(nextTotal.index, 1);
            }
          }
        }
      }

      setAnswers(answers);
    } else {
      const questionIds = ['1', '2', '2A', '3', '3A', '4', '4A', '5', '6', '7', '8', '8A', '9'];
      const answerQueue = [];

      questionIds.forEach(id => {
        answers.forEach(a => {
          console.error();
          if (a.id === id) {
            answerQueue.push(a);
          }
        });
        if (q.id === id) {
          answerQueue.push(q);
        }
      });

      setAnswers(answerQueue);
      // setAnswers(answers.concat([q]));
    }

    setQuestionIndex(questionIndex + 1);
    setQuestion(getCurrentQuestion(questions, nextId));

    window.scrollTo(0, 0);
  };

  const prevStep = (event, q) => {
    if (answers.length < 1) {
      return;
    }
    const answer = answers[questionIndex - 1];

    setQuestionIndex(questionIndex - 1);

    const question = getCurrentQuestion(questions, answer.id);

    setQuestion(question);

    window.scrollTo(0, 0);
  };

  const nextStepWithEmail = (event, q) => {
    setEmail(q.email);
    setName(q.name);
    setPhone(q.phone);

    setQuestionIndex(questionIndex + 1);
    setQuestion(getCurrentQuestion(questions, q.nextId));
    
    window.scrollTo(0, 0);
  };

  const updateStep = q => {
    setQuestion(getCurrentQuestion(questions, q.id));
  };

  return (
    <Container>
      <TopBelt />
      <BoxContainer>
        {!SUMMARY_STEPS.includes(question.id) ? (
          <Question
            question={question}
            answers={answers}
            updateQuestions={updateQuestions}
            questionIndex={questionIndex}
            updateStep={updateStep}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        ) : null}

        {question.id === 'summary-4B' ? <Summary4B prevStep={prevStep} /> : null}
        {question.id === 'summary-email' ? (
          <SummaryEmail
            answers={answers}
            question={question}
            questionIndex={questionIndex}
            prevStep={prevStep}
            nextStepWithEmail={nextStepWithEmail}
          />
        ) : null}
        {question.id === 'summary-without-help' ? <SummaryWithoutHelp prevStep={prevStep} /> : null}
      </BoxContainer>
      {question.id === 'summary-form' ? <SummaryForm answers={answers} email={email} /> : null}
    </Container>
  );
};
