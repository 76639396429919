import React, { FC, memo } from 'react';

import { Button } from 'components';

import PhoneImg from 'assets/images/phone-3.png';

import { Container, InfoContainer, ContactContainer, EmailAddress, PhoneNumber, ActionsContainer } from './styles';

export const Summary4B: FC<any> = memo(props => {
  const { prevStep } = props;

  return (
    <Container>
      <InfoContainer>
        prosimy o kontakt telefoniczny z Radcą Prawnym, Naszym specjalistom od Upadłości Przedsiębiorstw:
      </InfoContainer>
      <ContactContainer>
        <PhoneNumber href="tel:790 877 792">
          <img src={PhoneImg} alt="+48 790 877 792" />
        </PhoneNumber>
        <EmailAddress>
          <a href="mailto:biuro@radcaslask.pl">biuro@radcaslask.pl</a>
        </EmailAddress>
      </ContactContainer>
      <ActionsContainer>
        <Button type="button" label="Wróć" style={{ backgroundColor: '#E1E0DE' }} onClick={e => prevStep(e)} />
      </ActionsContainer>
    </Container>
  );
});
