import React, { FC, memo, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Container, InputContainer, RadioInput, RadioCheckmark, RadioLabel } from './styles';

export const Radio: FC<any> = props => {
  const { checked, value, name, label, onRadioChange } = props;
  const id = `${name}-${value}-${Date.now()}`;

  const onRadioChecked = () => {
    // onRadioChange({ checked: true, value });
    onRadioChange(value);
  };

  const onChange = event => {
    onRadioChange(value);
    // setCheckedState(true);
  };

  return (
    <Container>
      <InputContainer>
        <RadioInput
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={checked}
          onClick={onRadioChecked}
          onChange={onChange}
        />
        <RadioCheckmark htmlFor={id} className="checkmark" onClick={onRadioChecked} />
      </InputContainer>
      <RadioLabel htmlFor={id} onClick={onRadioChecked}>
        {ReactHtmlParser(label)}
      </RadioLabel>
    </Container>
  );
};
