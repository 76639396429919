import React, { FC, Fragment, memo } from 'react';

import { Radio } from 'components/elements';

export const RadioGroup: FC<any> = memo(props => {
  const { name, options, setOptions } = props;

  const onRadioChange = event => {
    if (!event.checked) {
      return;
    }

    setOptions(
      options.map(o => {
        o.checked = o.value === event.value;
        return o;
      }),
    );
  };

  return (
    <Fragment>
      {options.map((option, i) => (
        <Fragment key={`Radio${name}${i}`}>
          <Radio name={name} onRadioChange={onRadioChange} {...option} />
        </Fragment>
      ))}
    </Fragment>
  );
});
